import * as React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/Seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PageWithComments from "../components/PageWithComment"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  // const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  console.log('POST');
  console.log(post);
  return (
    // <Layout location={location} title={siteTitle}>
    <>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post flex flex-col "
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="flex flex-col max-w-4xl w-full text-3xl place-self-center">
          <div className='font-bold text-4xl pt-10 pb-2 text-center'>{post.frontmatter.title}</div>

          <div className="text-sm flex flex-col md:flex-row place-content-center max-w-2xl w-full gap-5 place-self-center px-3 md:px-14">
            {/* <div>{post.frontmatter.category}</div> */}
            <div>{post.frontmatter.date}</div>
            <div>{post.timeToRead} phút đọc</div>
            <div className="flex flex-row gap-5">
              {post.frontmatter.tags.map(tag => {
                return <div className="bg-gray-200 rounded-xl px-3">{tag}</div>
              })}
            </div>

          </div>

          {/* <div className="text-sm flex flex-col md:flex-row place-content-center md:place-content-between max-w-2xl w-full place-self-center">
            <div className='flex flex-row gap-2 place-content-center'>
              <StaticImage src='../images/category-icon.svg' alt='calendar-icon' width={20} height={20} />
              {post.frontmatter.category}
            </div>
            <div className='flex flex-row gap-2 place-content-center'>
              <StaticImage src='../images/calendar-icon.svg' alt='calendar-icon' width={20} height={20} />
              {post.frontmatter.date}
            </div>
            <div className="flex flex-row gap-5 place-content-center">
              {post.frontmatter.tags.map(tag => {
                return (
                  <div key={tag} className='flex flex-row gap-0 '>
                    <StaticImage src='../images/hashtag-icon.svg' alt='calendar-icon' width={20} height={20} />
                    {tag}
                  </div>
                )
              })}
            </div>
          </div> */}

        </header>
        <div className='py-10'>
          <GatsbyImage image={getImage(post.frontmatter.cover)} alt={post.frontmatter.title} className="max-h-[650px] w-full" objectFit="cover" />
        </div>

        <section
          className='place-self-center prose-lg prose px-3 md:px-14'
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <section className="pt-10 place-self-center max-w-4xl w-full px-3 md:px-14">
          <PageWithComments />
        </section>
        
        <hr className="mt-10 pt-5"/>

        
      </article>

      <div className="flex place-content-center pt-5">
        <nav className="blog-post-nav max-w-5xl w-full">
          {/* <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        > */}
          <ul className="flex wrap place-content-between list-none p-0 gap-x-10 w-full">
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
      {/* </Layout> */}
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "D MMMM YYYY", locale: "vi")
        description
        category
        tags   
        cover {
          childImageSharp {
            gatsbyImageData
          }
        }     
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
